/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.2.1/dist/jquery.min.js
 * - /npm/jquery-migrate@3.0.0/dist/jquery-migrate.min.js
 * - /npm/blazy@1.8.2/blazy.min.js
 * - /npm/magnific-popup@1.1.0/dist/jquery.magnific-popup.min.js
 * - /npm/slick-carousel@1.7.1/slick/slick.min.js
 * - /npm/lightgallery@1.6.6/dist/js/lightgallery-all.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
